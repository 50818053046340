const cookieName = 'ib-rodo-cookie';

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

export default class Cookz {
  init() {
    const cookieRodo = getCookie(cookieName);
    if (cookieRodo === 'null'
        || cookieRodo === ""
        || cookieRodo === null
        || cookieRodo === undefined
        || cookieRodo === 'undefined') {
        $(".ib-rodo").removeClass('d-none');
    }

    $(".ib-rodo .rodo-accept").on('click', function() {
      setCookie(cookieName, 1, 30);
      $(".ib-rodo").fadeOut();
    });
  }
}
